
import CameraListItem from "@/components/CameraListItem"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"

export default {
  name: "BreadcrumbItem",
  components: { CameraListItem },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapStores(useCameraStore),
    isCamera() {
      return this.item.isCamera
    },
    hasOtherItems() {
      return this.item.otherItems && this.item.otherItems.length > 0
    },
  },
}
