
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { allowKeypress } from "@evercam/shared/utils"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import UserPersonaField from "@/components/persona/UserPersonaField"

export default {
  name: "UserBsaicInfosDialog",
  components: { UserPersonaField },
  data() {
    return {
      newTelephone: null,
      newPersona: null,
      displayAccountInformationDialog: true,
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  beforeMount() {
    this.displayAccountInformationDialog =
      !this.accountStore.preventAccountInformationDialog
  },
  methods: {
    async saveUserData() {
      try {
        await EvercamApi.users.update(this.accountStore.email, {
          persona: this.newPersona,
          telephone: this.newTelephone,
        })
        this.accountStore.persona = this.accountStore.persona || this.newPersona
        this.accountStore.telephone =
          this.accountStore.telephone || this.newTelephone
        this.$emit("close")
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.account_update_user_failed"),
          error,
        })
      }
    },
    allowKeypress,
  },
}
