
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useAccountStore } from "@/stores/account"

export default {
  data() {
    return {
      menu: false,
      isDarkMode: "",
    }
  },
  computed: {
    ...mapStores(useLayoutStore, useAccountStore),
    initials() {
      return this.accountStore.fullname
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
    },
  },
  watch: {
    isDarkMode(val) {
      this.$theme.switchTheme({ isDark: val })
    },
  },
  mounted() {
    this.$setTimeout(() => {
      this.isDarkMode = this.$vuetify.theme.dark
    }, 200)
  },
  methods: {
    async signOut() {
      if (document.getElementById("zohohc-asap-web-layoutbox")) {
        document.getElementById("zohohc-asap-web-layoutbox").style.display =
          "none"
      }
      await this.accountStore.logout({ persistCurrentUrl: false })
    },
    toggleTheme() {
      this.isDarkMode = !this.isDarkMode
    },
  },
}
