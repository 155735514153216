
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { INGEST_UPLOADS_TYPES } from "@evercam/shared/constants/ingest"
import { mapStores } from "pinia"
export default {
  data() {
    return {
      isMenuActive: true,
      hideTooltip: false,
    }
  },
  computed: {
    ...mapStores(useIngestFileUploaderStore),
    isUploading() {
      return this.ingestFileUploaderStore.uploadStats.reduce(
        (acc, state) => state.isUploading || acc,
        false
      )
    },
    hasUploaded() {
      return this.ingestFileUploaderStore.uploadStats.reduce(
        (acc, state) => state.uploadFinished || state.isUploading || acc,
        false
      )
    },
    finished() {
      return this.ingestFileUploaderStore.uploadStats.reduce((acc, state) => {
        if (state.isProcessing) {
          acc.push(state)
        }

        return acc
      }, [])
    },
    bimUploads() {
      return this.ingestFileUploaderStore.uploadStats.reduce((acc, state) => {
        if (
          state.uploadType === INGEST_UPLOADS_TYPES.bimUploads &&
          !state.isProcessing
        ) {
          acc.push(state)
        }

        return acc
      }, [])
    },
    droneUploads() {
      return this.ingestFileUploaderStore.uploadStats.reduce((acc, state) => {
        if (
          state.uploadType === INGEST_UPLOADS_TYPES.droneUploads &&
          !state.isProcessing
        ) {
          acc.push(state)
        }

        return acc
      }, [])
    },
  },
  watch: {
    isMenuActive() {
      this.hideTooltip = this.isMenuActive
    },
    "ingestFileUploaderStore.currentUploadStats.isUploading"(value) {
      if (value) {
        this.isMenuActive = false
        setTimeout(() => {
          this.isMenuActive = true
        }, 100)
      } else {
        this.isMenuActive = false
      }
    },
  },
  mounted() {
    window.onbeforeunload = () => {
      if (this.isUploading) {
        return "Your files are not completely uploaded..."
      }
    }
  },
}
