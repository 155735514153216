
import { mapStores } from "pinia"
import { useConnectorStore } from "@/stores/connector"

export default {
  name: "ConnectorPromptDialog",
  computed: {
    ...mapStores(useConnectorStore),
  },
}
