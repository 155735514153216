
import Vue from "vue"
import { Persona } from "@evercam/shared/types/user"
export default Vue.extend({
  name: "UserPersonaField",
  props: {
    rules: {
      type: [String, Object],
      default: () => "",
    },
    vid: {
      type: String,
      default: () => "",
    },
    dataTestId: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      items: [
        {
          text: this.$t("forms.persona.items.hq"),
          value: Persona.HQ,
        },
        {
          text: this.$t("forms.persona.items.pm"),
          value: Persona.PM,
        },
        {
          text: this.$t("forms.persona.items.qs"),
          value: Persona.QS,
        },
        {
          text: this.$t("forms.persona.items.marketing"),
          value: Persona.Marketing,
        },
        {
          text: this.$t("forms.persona.items.hs"),
          value: Persona.HS,
        },
        {
          text: this.$t("forms.persona.items.di"),
          value: Persona.DI,
        },
        {
          text: this.$t("forms.persona.items.other"),
          value: Persona.Other,
        },
      ],
    }
  },
  mounted() {
    if (this.$permissions.user.is.admin()) {
      this.items = [
        ...this.items,
        {
          text: "None: Not Construction Related",
          value: "None",
        },
      ]
    }
  },
  methods: {
    onPersonaItemSelected(selectedItem) {
      const { value } = selectedItem as { text: string; value: Persona }
      this.$emit("input", value)
    },
  },
})
