
import Vue from "vue"

export default Vue.extend({
  name: "Breadcrumbs",
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
})
